import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NavigationItem } from 'src/app/components/navigation/navigation.model';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { SidebarComponent } from '../../common/sidebar/sidebar.component';
import { defaultNavigation } from 'src/app/core/navigation/navigation';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagesComponent } from 'src/app/components/languages/languages.component';
import { MediaWatcherService } from 'src/app/core/media-watcher/media-watcher.service';
import { HorizontalSidebarComponent } from '../../common/horizontal-sidebar/horizontal-sidebar.component';

@Component({
  selector: 'admin-layout',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
    SidebarComponent,
    TranslateModule,
    LanguagesComponent,
    HorizontalSidebarComponent,
  ],
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  navigation!: NavigationItem[];
  isScreenSmall!: boolean;
  constructor(
    private _navigationService: NavigationService,
    private _mediaWatcherService: MediaWatcherService
  ) {}

  ngOnInit(): void {
    // Set navigation
    this._navigationService.navigation = defaultNavigation;

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: NavigationItem[]) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
