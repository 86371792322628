import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, retryWhen, switchMap, tap } from 'rxjs/operators';
import { LoadingBarService } from 'src/app/components/loading-bar/loading-bar.service';
import { NotRespondingService } from 'src/app/components/not-responding/not-responding.service';

export const retryInterceptor: HttpInterceptorFn = (req, next) => {
  const notRespondingService = inject(NotRespondingService);
  const loadingBarService = inject(LoadingBarService);
  return next(req).pipe(
    retryWhen((error: Observable<any>) => {
      return error.pipe(
        switchMap((err) => {
          // Throw error if code is not 0 or 503
          if (err.status !== 0 && err.status !== 503) {
            return throwError(() => {
              throw err;
            });
          }
          loadingBarService.hide();
          // Check
          notRespondingService.check(true, req.url);

          return notRespondingService.retry.pipe(
            map((val) => {
              loadingBarService.show();
              notRespondingService.closeDialog();
              return of(val);
            })
          );
        })
      );
    }),
    tap((event) => {
      if (event instanceof HttpResponse) {
        notRespondingService.check(false, req.url);
      }
    })
  );
};
