<div
  class="w-20 flex flex-row flex-col h-full py-4 items-center bg-grey-800 overflow-hidden"
>
  <!-- Navigation -->
  <div class="flex-grow w-full overflow-auto">
    <div class="h-full flex flex-row flex-col px-2">
      <div class="text-white">
        <button
          [disabled]="businessUnits.length <= 0"
          (click)="show = !show"
          class="flex flex-col gap-2 justify-center items-center py-4 px-2 w-full bg-grey-600 mb-2 rounded-md text-center transition"
          [matTooltip]="
            selectedBusinessUnit?.displayName ||
            selectedBusinessUnit?.name ||
            ''
          "
          [matTooltipPosition]="'left'"
          [ngClass]="[
            !checkDisabled()
              ? 'cursor-pointer opacity-100 hover:bg-grey-500'
              : '!cursor-not-allowed opacity-60',
            !checkDisabled() && businessUnitModel != 0
              ? 'text-warn'
              : 'text-white'
          ]"
        >
          <mat-icon [svgIcon]="'design:store'"></mat-icon>
          <div
            *ngIf="selectedBusinessUnit"
            class="uppercase truncate w-full text-xs text-white"
          >
            {{ selectedBusinessUnit.displayName || selectedBusinessUnit.name }}
          </div>
        </button>
      </div>
      <div
        *ngFor="let item of navigation"
        [ngClass]="item.classes?.wrapper"
        class="mb-2 flex flex-col overflow-hidden {{
          item.children!.length >= 2 ? 'rounded-md' : 'rounded-lg'
        }}"
      >
        <ng-container *ngFor="let child of item.children; let i = index">
          <a
            *hasResource="child.id == 'SM' ? child.id : ''"
            class="relative flex items-center flex-col justify-center transition px-2 py-4 text-white bg-grey-600"
            [ngClass]="
              (!child.disabled && !checkDisabled()) || child.id == 'setting'
                ? 'cursor-pointer opacity-100 hover:bg-grey-500'
                : '!cursor-not-allowed opacity-60'
            "
            [routerLink]="
              child.id == 'setting'
                ? child.link
                : (!child.disabled && !checkDisabled() && child.link) || null
            "
            [matTooltip]="
              (!child.disabled && !checkDisabled() && child.title) || ''
                | translate
            "
            [matTooltipPosition]="'left'"
            [queryParamsHandling]="'preserve'"
          >
            <mat-icon
              [routerLinkActive]="'text-warn'"
              [svgIcon]="child.icon ?? ''"
              [ngClass]="child.classes?.icon"
            ></mat-icon>
          </a>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Footer -->

  <div class="px-2 mt-auto w-full">
    <a
      class="rounded-md relative flex-shrink-0 cursor-pointer bg-grey-600 flex items-center justify-center text-white w-full px-2 py-4 hover:bg-grey-500 transition"
      routerLink="/profile"
      [queryParamsHandling]="'preserve'"
      [matTooltipPosition]="'left'"
      [matTooltip]="'Profile.AccountInfo' | translate"
    >
      <mat-icon
        [routerLinkActive]="'text-warn'"
        svgIcon="mat_outline:account_circle"
        class="icon-size-7"
      ></mat-icon>
    </a>
  </div>
</div>

<div
  *ngIf="show"
  [@fadeIn]="show"
  [@fadeOut]="!show"
  class="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 z-[1111]"
  (click)="show = false"
>
  <div
    (click)="$event.stopPropagation()"
    class="absolute top-0 right-20 w-64 bg-surface h-full p-6 overflow-auto"
  >
    <div class="flex gap-6">
      <div class="flex flex-col flex-grow flex-shrink-0">
        <div class="text-base text-darkgreen uppercase font-bold mb-4">
          {{ "BusinessUnit.Title" | translate }}
        </div>
        <div class="flex flex-col -ml-3">
          <mat-radio-group
            color="accent"
            class="flex flex-col"
            name="businessUnit"
            [(ngModel)]="businessUnitModel"
            (ngModelChange)="onBusinessUnitChange($event)"
          >
            <mat-radio-button
              *ngFor="let item of businessUnits"
              [value]="item.id"
            >
              {{ item.displayName || item.name }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
