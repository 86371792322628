import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { apiPrefixInterceptor } from './core/http/interceptors/api-prefix.interceptor';
import { authInterceptor } from './core/auth/auth.interceptor';
import { loadingInterceptor } from './core/http/interceptors/loading.interceptor';
import { ToastComponent } from './components/toast/toast.component';
import { ToastrModule } from 'ngx-toastr';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { APP_CONFIG } from './core/config/config.service';
import { appConfig as config } from './core/config/app.config';
import { errorHandlerInterceptor } from './core/http/interceptors/error-handler.interceptor';
import { retryInterceptor } from './core/http/interceptors/retry.interceptor';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
    thousandSeparator: ',',
    clearIfNotMatch: false,
    decimalMarker: '.',
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        loadingInterceptor,
        apiPrefixInterceptor,
        authInterceptor,
        errorHandlerInterceptor,
        retryInterceptor,
      ])
    ),
    importProvidersFrom([
      ToastrModule.forRoot({
        toastComponent: ToastComponent,
        toastClass: 'custom-toast',
        positionClass: 'toast-bottom-left',
      }),
      TranslateModule.forRoot(),
    ]),
    provideEnvironmentNgxMask(maskConfigFunction),
    { provide: APP_CONFIG, useValue: config },
  ],
};
