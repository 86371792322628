import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, ActivatedRoute, Params, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  map,
  distinctUntilChanged,
  Subject,
  combineLatest,
  takeUntil,
  switchMap,
  of,
} from 'rxjs';
import { ApiEndpoint, IPagingResponse } from 'src/app/core/http/api';
import { NavigationItem } from 'src/app/core/navigation/navigation.model';
import { UserResourceDirective } from 'src/app/directives/has-resource/user-resource.directive';
import { IBusinessUnit } from 'src/app/modules/categories/business-unit/business-unit';
import { ReusableService } from 'src/app/services/reusable.service';

@Component({
  selector: 'app-horizontal-sidebar',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    MatMenuModule,
    UserResourceDirective,
    MatRadioModule,
    FormsModule
  ],
  templateUrl: './horizontal-sidebar.component.html',
  styleUrl: './horizontal-sidebar.component.scss',
})
export class HorizontalSidebarComponent {
  @Input() navigation!: NavigationItem[];
  constructor(
    private _reusableService: ReusableService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  businessUnitId$ = this._activatedRoute.queryParams.pipe(
    map((params: Params) => {
      return params['businessUnitId'];
    }),
    distinctUntilChanged()
  );

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  businessUnits: IBusinessUnit[] & any = [];
  selectedBusinessUnit: IBusinessUnit | null | undefined = null;
  showBusinessUnits = false;
  businessUnitModel = 0;
  ngOnInit(): void {
    combineLatest([this.businessUnitId$, this.getBusinessUnits()])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([businessUnitId]) => {
        this.selectedBusinessUnit = this.businessUnits.find(
          (item: IBusinessUnit) => item.id == businessUnitId
        );
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  onBusinessUnitChange(event: any) {
    this._router.navigate([], {
      queryParams: { businessUnitId: event == 0 ? null : event },
      queryParamsHandling: 'merge',
    });
  }

  getBusinessUnits() {
    const params = {
      filterOptions: {
        pageIndex: 1,
        pageSize: 999,
        sorter: {
          modifiedDate: 'desc',
        },
      },
      search: '',
    };
    return this._reusableService
      .get(ApiEndpoint.GetListBusinessUnits, params)
      .pipe(
        switchMap((res: IPagingResponse<IBusinessUnit>) => {
          this.businessUnits = [
            ...res.result.data.filter((item) => item.isActive),
          ];
          return of(res.result.data);
        })
      );
  }

  checkDisabled(): boolean {
    return this.businessUnits.length <= 0;
  }
}
