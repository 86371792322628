import { Component, OnInit } from '@angular/core';
import { NotRespondingService } from './not-responding.service';
import { MatDialogClose } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [MatDialogClose, MatButtonModule, TranslateModule, MatIconModule],
  selector: 'app-not-responding',
  templateUrl: './not-responding.component.html',
  styleUrls: ['./not-responding.component.scss'],
})
export class NotRespondingComponent implements OnInit {
  constructor(private _notRespondingService: NotRespondingService) {}

  ngOnInit(): void {}

  retry() {
    this._notRespondingService.setRetry(true);
  }
}
