<div class="dialog">
  <div class="dialog__close">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-hint" [svgIcon]="'mat_outline:close'"></mat-icon>
    </button>
  </div>
  <!-- Header -->
  <div class="dialog__header">
    <h4 class="font-semibold uppercase text-2xl">
      {{ "Errors.SomethingWentWrong" | translate }}
    </h4>
  </div>

  <!-- Body -->
  <div class="dialog__body">
    <div class="flex flex-col gap-10 items-center">
      <img src="assets/images/404.svg" alt="" />
      <p class="text-secondary">{{ "Errors.Retry" | translate }}</p>
    </div>
  </div>

  <!-- Footer -->
  <div class="dialog__footer text-right">
    <div class="flex justify-center">
      <button mat-flat-button type="button" (click)="retry()" color="primary">
        {{ "Action.Retry" | translate }}
      </button>
    </div>
  </div>
</div>
