import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { NotRespondingComponent } from './not-responding.component';

@Injectable({
  providedIn: 'root',
})
export class NotRespondingService {
  private _retry: Subject<boolean> = new Subject();
  private _urlMap = new Map<string, boolean>();

  private _dialogRef!: MatDialogRef<NotRespondingComponent> | null;
  /**
   * Setter and getter for retry
   * */
  get retry(): Observable<boolean> {
    return this._retry.asObservable();
  }

  setRetry(value: boolean): void {
    this._retry.next(value);
  }

  constructor(private _dialog: MatDialog) {}

  openDialog() {
    if (this._dialogRef) {
      return;
    }
    this._dialogRef = this._dialog.open(NotRespondingComponent, {
      width: '500px',
      panelClass: 'dialog__wrapper',
    });

    this._dialogRef.beforeClosed().subscribe((_) => {
      this._dialogRef = null;
    });
  }

  closeDialog() {
    if (!this._dialogRef) {
      return;
    }
    this._dialogRef.close();
  }

  check(status: boolean, url: string) {
    if (status === true) {
      this._urlMap.set(url, status);
      this.openDialog();
    } else if (status === false && this._urlMap.has(url)) {
      this._urlMap.delete(url);
    }

    if (this._urlMap.size === 0) {
      this.closeDialog();
    }
  }
}
