export const ErrorCodesToTranslations: any = {
  ERR_001_USER_NOT_EXISTS: 'Errors.UserNotExists',
  ERR_002_PASSWORD_NOT_CORRECT: 'Errors.PasswordNotCorrect',
  ERR_003_USER_ALREADY_EXSITS: 'Errors.UserAlreadyExists',
  ERR_004_DATA_NOT_EXISTS: 'Errors.DataNotExists',
  ERR_008_DATA_INPUT_INVALID: 'Errors.DataInputInvalid',
  ERR_009_INVOICE_EXISTS: 'Errors.InvoiceExists',
  ERR_012_UPLOAD_FILE_ERROR: 'Errors.UploadFileError',
  ERR_014_ENUM_INVALID_OR_NOT_DEFINED: 'Errors.EnumInvalidOrNotDefined',
  ERR_015_ORDER_CANNOT_DELETE: 'Errors.OrderCannotDelete',
  ERR_017_ORDER_NOT_VALID_TO_ADJUSTMENT: 'Error.OrderNotValidToAdjustment',
  ERR_019_WAREHOUSE_NOTE_NOT_VALID_TO_PROCESS:
    'Errors.WarehouseNoteNotValidToProcess',
};
